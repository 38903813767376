import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme:Theme) => ({
    root: {
        boxSizing: "border-box",
        width: "100%",
    },
    label: {
        display:"block",
        fontSize: "0.87rem",
        fontWeight:400,

        "& span": {
            color: theme.palette.error.main
        },

        "& .small": {
            fontSize: "0.83rem",
            color: theme.palette.secondary.main
        }
    }
}));

interface IFormGroup {
    id:string,
    label:string,
    required:boolean,
    children:any,
    className?:string,
    helperText?:string
}

const FormGroup = ({id, label, required, children, className, helperText}:IFormGroup) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${className}`}>
            <label className={classes.label} htmlFor={id}>
                {label} {required ? <span>*</span> : null}
                <small className="small">{helperText}</small>
            </label>
            {children}
        </div>
    )
}

export default FormGroup
import { createContext } from "react";

export type PageState = {
    isPageLoading: boolean,
    setIsPageLoading: Function
}

export const pageDefault:PageState = {
    isPageLoading: false,
    setIsPageLoading: () => {}
}

const PageContext = createContext<PageState>(pageDefault);
export default PageContext;
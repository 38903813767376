import { useContext, useEffect, useState } from 'react';
import { io } from "socket.io-client";
import { Switch, Redirect, Route } from 'react-router-dom';
import { Box, makeStyles, Theme } from '@material-ui/core';
import ProtectedRoute from './ProtectedRoute';
import Navigators from 'components/Navigators';
import MenuContext from 'context/MenuContext';
import AuthContext from 'context/AuthContext';
import { KeepAlive } from 'actions/security/KeepAlive';
import Pages, { IPage } from './Pages';
import { IdleTimerProvider } from 'react-idle-timer';
import { Active, Idle, Logout } from 'actions/security/Actions';
import SimpleModal from 'components/common/SimpleModal';
import IdlePrompt from './IdlePrompt';
import PageContext from 'context/PageContext';
import PageLoaderBackDrop from './PageLoaderBackdrop';
import { INonMenuPath, NonMenuPath } from 'components/sidebar/menu/MenuData';
import SocketService from 'utils/SocketService';
import SocketContext from 'context/SocketContext';

const useStyles = makeStyles((theme: Theme) => ({
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		background: theme.palette.background.paper,
	},
	content: {
		flexGrow: 1,
		width: "100%",
	}
}));

const PrivateRoute = () => {
	const classes = useStyles();
	const { paths } = useContext(MenuContext);
	const { session, setSession } = useContext(AuthContext);

	const [idlePromptOpen, setIdlePromptOpen] = useState(false);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [socket, setSocket] = useState<any>(null);

	const timeout = parseInt(process.env.REACT_APP_IDLE_LOGOUT_TIME as string);
	const csrf = session.csrf as string;
	const token = session.token as string;

	useEffect(() => {
		let interval = setInterval(async () => {
			const response = await KeepAlive(session.token, csrf);
			if (response.success) {
				if (response.code === "200.100.000") {
					setSession({
						...session,
						isAuthenticated: true,
						token: response.data.token
					});
				}
				return;
			}
			setSession({
				...session,
				isAuthenticate: false,
				token: null
			});
		}, 60000);

		// if(socket === null) {			
		// 	var io = new SocketService(token);
		// 	const sock:any = io.getInstance();

		// 	sock.on("connect", () => {
		// 		sock.emit("join-room", "global-notif", (message: string) => {
		// 			console.log(message);
		// 		});
		// 		setSocket(sock);
		// 	});
		// }

		return () => {
			clearInterval(interval);
		}
	}, [session]);

	const getPages = (pages: Array<IPage>) => {
		const collection: Array<IPage> = [];

		pages.forEach((page) => {
			const noneMenu = NonMenuPath.find((x: INonMenuPath) => x.path === page.path);
			if (paths.includes(page.path) || typeof (noneMenu) !== "undefined") {
				collection.push(page);
			}
		});

		return collection;
	}

	const onIdle = async () => {
		await Idle(csrf, session.token);
		setIdlePromptOpen(true);
	}

	const handleCloseModal = async () => {
		await Active(csrf, session.token);
		setIdlePromptOpen(false);
	}

	const handleLogout = async () => {
		await Logout(csrf, session.token);
		setSession({ ...session, isAuthenticated: false });
	}

	return (
		<IdleTimerProvider
			timeout={1000 * timeout}
			onIdle={onIdle}
			startOnMount={true}
			crossTab={true}
		>
			<SocketContext.Provider value={{socket}}>
				<PageContext.Provider value={{ isPageLoading, setIsPageLoading }}>
					{isPageLoading ? <PageLoaderBackDrop /> : null}
					<Navigators />
					<Box className={classes.content}>
						<Box className={classes.toolbar} />
						{
							paths.length > 0 ?
								<Switch>
									{
										getPages(Pages).map((page, index) => {
											return (
												<ProtectedRoute path={page.path} exact component={page.component} key={index} />
											)
										})
									}
									<Route exact>
										<Redirect to="/" />
									</Route>
								</Switch> : null
						}
					</Box>

					<SimpleModal open={idlePromptOpen} handleClose={handleCloseModal}>
						<IdlePrompt handleClose={handleCloseModal} handleLogout={handleLogout} />
					</SimpleModal>
				</PageContext.Provider>
			</SocketContext.Provider>
		</IdleTimerProvider>
	)
}

export default PrivateRoute;
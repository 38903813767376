import * as Page from 'components/main/Components';
export interface IPage {
    path: string,
    component: any
}

const Pages: Array<IPage> = [
    {
		path: "/",
		component: Page.Dashboard
	},
	{
		path: "/transactions",
		component: Page.Transactions
	},
	{
		path: "/transactions/create",
		component: Page.CreateTransaction
	},
	{
		path: "/transactions/payment",
		component: Page.CompleteTransactionPayment
	},
	{
		path: "/tickets",
		component: Page.Tickets
	},
	{
		path: "/tickets/create",
		component: Page.CreateTicket
	},
	{
		path: "/tickets/thread",
		component: Page.TicketThread
	},
	{
		path: "/messages",
		component: Page.Messages
	},
	{
		path: "/monitoring/sales",
		component: Page.LiveSales
	},
	{
		path: "/monitoring/tickets",
		component: Page.LiveTickets
	},
	{
		path: "/payments",
		component: Page.Payments
	},
	{
		path: "/products",
		component: Page.Products
	},
	{
		path: "/products/create",
		component: Page.CreateProduct
	},
	{
		path: "/products/edit",
		component: Page.EditProduct
	},
	{
		path: "/warehouse",
		component: Page.ProductWarehouse
	},
	{
		path: "/affiliates",
		component: Page.Affiliates
	},
	{
		path: "/affiliates/products",
		component: Page.AffiliateProducts
	},
	{
		path: "/affiliates/store",
		component: Page.AffiliateStore
	},
	{
		path: "/leads",
		component: Page.Leads
	},
	{
		path: "/customers",
		component: Page.Customers
	},
	{
		path: "/admin/manage-users",
		component: Page.AdminUsers
	},
	{
		path: "/admin/manage-users/create",
		component: Page.AdminCreateUser
	},
	{
		path: "/admin/manage-users/edit",
		component: Page.AdminEditUser
	},
	{
		path: "/admin/manage-roles",
		component: Page.AdminRoles
	},
	{
		path: "/admin/manage-permissions",
		component: Page.AdminUserPermissions
	},
	{
		path: "/settings",
		component: Page.AdminSettings
	},
	{
		path: "/reports",
		component: Page.Reports
	},
	{
		path: "/reports/scheduled",
		component: Page.ScheduledReports
	},
];

export default Pages;
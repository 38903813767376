import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import OverlayContainer from 'components/common/OverlayContainer';
import AuthContext from 'context/AuthContext';
import { GetRooms } from 'actions/chat/Actions';
import Loading from 'components/common/Loading';
import NoRecordFound from 'components/common/NoRecordFound';
import { Icon, IconButton, Typography } from '@material-ui/core';
import { toHumanDate } from 'components/helpers/DateHelper';
import SimpleModal from 'components/common/SimpleModal';
import Contacts from './Contacts';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",

        "& .action": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "-56px"
        }
    },
    paper: {
        padding: theme.spacing(2, 0)
    },
    empty: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",

        "& .MuiSvgIcon-root": {
            color: theme.palette.grey[300],
            width: "4rem",
            height: "4rem"
        },

        "& .MuiTypography-body2": {
            color: theme.palette.secondary.main,
        }
    },
    btnWrapper: {
        marginTop: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",

        "& button": {
            marginBottom: theme.spacing(2),
            minWidth: "unset",
            maxWidth: "150px"
        }
    },
    rooms: {
        "& .room": {
            padding: "16px 10px",
            borderBottom: `1px dotted ${theme.palette.secondary.main}`,
            display: "block",

            "&:hover": {
                background: theme.palette.secondary.light,
            }
        }
    },
    modal: {
        "& .child": {
            width: "100%",
            maxWidth: "700px"
        }
    },
}));

const Messenger = ({ callback }: { callback: Function }) => {
    const { session } = useContext(AuthContext);
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const token = session.token as string;
    const permissions = session.permissions;

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async (term: string = "") => {
        setLoading(true);

        let query = `?term=${term}`
        const result = await GetRooms(query, token)
        if (result.success) {
            setRooms(result.data);
        }

        setLoading(false);
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleCallback = async (event: string) => {
        if (event !== "close") {
            await fetchRooms();
        }
        setOpenModal(false);
    }

    const newContact = async () => {
        setOpenModal(true);
    }

    const classes = useStyles();
    return (
        <OverlayContainer
            label="Chat Messages"
            hasCTA={false}
        >
            <div className={classes.root}>
                {permissions.includes(btoa("_messages.group.create")) ? (
                    <div className="action">
                        <IconButton onClick={newContact} color="primary">
                            <Icon>add_circle</Icon>
                        </IconButton>
                    </div>
                ) : null}

                {loading ? <Loading /> : rooms.length === 0 ?
                    <Empty /> : <Rooms rooms={rooms} callback={callback} />
                }
            </div>

            <SimpleModal open={openModal} handleClose={handleModalClose} className={classes.modal}>
                <Contacts handleCallback={handleCallback} />
            </SimpleModal>
        </OverlayContainer>
    )
}

const Rooms = ({ rooms, callback }: { rooms: any, callback: Function }) => {
    const { session } = useContext(AuthContext);

    const timezone = session.global?.timezone as string;
    const classes = useStyles();

    const handleRoomClick = (event: React.MouseEvent, id: string) => {
        event.preventDefault();
        callback(id);
    }

    return (
        <div className={classes.rooms}>
            {rooms.map((r: any) => (
                <a href="#" className="room" key={r.id} onClick={(e: any) => { handleRoomClick(e, r.id) }}>
                    <Typography variant="subtitle2">
                        {r.group}
                    </Typography>

                    <Typography variant="body2" color="secondary">
                        {r.lastMessage.replace(/(.{25})..+/, "$1…")}
                    </Typography>

                    <Typography variant="caption" color="secondary">
                        {toHumanDate(r.lastReplyDate, timezone)}
                    </Typography>
                </a>
            ))}
        </div>
    )
}

const Empty = () => {
    const classes = useStyles();
    return (
        <div className={classes.empty}>
            <NoRecordFound
                caption="You don't belong in any rooms yet"
            />
        </div>
    )
}

export default Messenger
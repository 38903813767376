import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';

import HeaderStyles from './HeaderStyles';

export const RenderMobile = ({mobileMoreAnchorEl, handleMobileMenuClose, handleProfileMenuOpen}: {mobileMoreAnchorEl:null | HTMLElement, handleMobileMenuClose: Function, handleProfileMenuOpen:Function}) => {
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const classes = HeaderStyles();

    return (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={() => handleMobileMenuClose()}
        >
            {/* <MenuItem>
                <IconButton aria-label="show 4 new mails">
                    <Badge badgeContent={4} classes={{badge: classes.customBadge}}>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>

            <MenuItem>
                <IconButton aria-label="show 11 new notifications">
                    <Badge badgeContent={11} classes={{badge: classes.customBadge}}>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}

            <MenuItem>
                <IconButton aria-label="account of current user">
                    <a className={classes.profileLink} href="/profile"><AccountCircle /></a>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );
}
import React, { MouseEventHandler } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import FormBtn from './form/FormBtn';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3),

        "& .header": {
            marginTop: theme.spacing(2),
            fontWeight: 500,
            fontSize: "1.2rem"
        },

        "& .content": {
            flex: 1,
            padding: theme.spacing(2, 0)
        },

        "& .btn-wrapper": {
            paddingBottom: theme.spacing(3),

            "& button": {
                marginTop: theme.spacing(1)
            }
        }
    },
}));

interface IContainer {
    label: string,
    children: any,
    className?: string,
    hasCTA?: boolean,
    ctaLabel?: string|undefined,
    ctaClick?: MouseEventHandler,
    cancelClick?: MouseEventHandler,
    disabled?: boolean
}

const OverlayContainer = ({ label, children, className, ctaLabel, ctaClick, cancelClick, disabled, hasCTA = true }: IContainer) => {
    const classes = useStyles();

    return (
        <div className={`${classes.root} ${className}`}>
            <Typography variant='subtitle1' className="header">
                {label}
            </Typography>

            <div className="content">
                {children}
            </div>

            {hasCTA ? (
                <div className="btn-wrapper">
                    <FormBtn color="primary" label={typeof(ctaLabel) === "undefined" ? "" : ctaLabel} variant="contained" onClick={ctaClick} disabled={disabled} />
                    <FormBtn color="secondary" label="Cancel" variant="outlined" onClick={cancelClick} />
                </div>
            ) : null}
        </div>
    )
}

export default OverlayContainer
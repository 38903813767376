import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "150px",

        "& .MuiCircularProgress-root": {
            width: "20px !important",
            height: "20px !important"
        },

        "& .MuiTypography-body2": {
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(1)
        }
    }
}));

const Loading = ({className}:{className?:string}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${className}`}>
            <CircularProgress />

            <Typography variant="body2" align="center">
                Please wait...
            </Typography>
        </div>
    )
}

export default Loading
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import HeaderStyles from './HeaderStyles';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { RenderDesktop } from './RenderDesktop';
import { RenderMobile } from './RenderMobile';

const Header = ({ open, handleDrawerOpen }: { open: boolean, handleDrawerOpen: Function }) => {
	const classes = HeaderStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	return (
		<div className={classes.grow}>
			<AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
				<Toolbar>
					<IconButton color="inherit" aria-label="open drawer" onClick={() => handleDrawerOpen()} edge="start" className={
						clsx(classes.menuButton, { [classes.hide]: open, })}>
						<MenuIcon color="primary" />
					</IconButton>

					<div className={classes.grow} />
					<MenuDesktop />
					<MenuMobile handleMobileMenuOpen={handleMobileMenuOpen} />
				</Toolbar>
			</AppBar>

			<RenderMobile mobileMoreAnchorEl={mobileMoreAnchorEl} handleMobileMenuClose={handleMobileMenuClose} handleProfileMenuOpen={handleProfileMenuOpen} />
			<RenderDesktop anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
		</div>
	);
}

export default Header;
import AuthContext from 'context/AuthContext';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { GetMessages } from 'actions/chat/Actions';
import NoRecordFound from 'components/common/NoRecordFound';
import Loading from 'components/common/Loading';
import { classNames } from 'react-select/src/utils';
import { toHumanDate } from 'components/helpers/DateHelper';
import { Icon, IconButton, Typography } from '@material-ui/core';
import FormGroup from 'components/common/form/FormGroup';
import InputField from 'components/common/form/InputField';
import SocketContext from 'context/SocketContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    empty: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",

        "& .MuiSvgIcon-root": {
            color: theme.palette.grey[300],
            width: "4rem",
            height: "4rem"
        },

        "& .MuiTypography-body2": {
            color: theme.palette.secondary.main,
        }
    },
    feed: {
        "& .header": {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2)
        },    

        "& .messages": {
            padding: theme.spacing(1),
            height: "calc(100vh - 170px)",
            overflowY:"auto",

            "&::-webkit-scrollbar": {
                width: "3px"
            },

            "&::-webkit-scrollbar-track": {
                background: theme.palette.secondary.light
            },

            "&::-webkit-scrollbar-thumb": {
                background: theme.palette.grey[300]
            },

            "& .message": {
                margin: theme.spacing(1, 0),
                
                "& .system": {
                    fontSize: "0.88rem",
                    color: theme.palette.secondary.main,
                    textAlign: "center",
                    padding: theme.spacing(0.6)
                },

                "& .text": {
                    padding: theme.spacing(1),

                    "& .MuiTypography-caption": {
                        color: theme.palette.secondary.main,
                        fontSize: "0.75rem"
                    },

                    "& .MuiTypography-body2": {
                        width: "fit-content",
                        borderRadius: "6px",
                        padding: "10px 15px",
                        background: theme.palette.secondary.light,
                    }
                },

                "&.bubble-right": {
                    "& .text": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        justifyContent: "end",

                        "& .MuiTypography-body2": {
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText
                        }
                    }
                }
            }
        },

        "& .textarea": {
            padding: theme.spacing(1),
            display: "flex",
            alignItems: "center",

            "& > *": {
                "&:hover": {
                    background: "transparent"
                }
            }
        }
    },
}));

const Messages = ({ room, callback }: { room: string, callback: Function }) => {
    const { session } = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [group, setGroup] = useState("");
    const [loading, setLoading] = useState(false);

    const token = session.token as string;

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        setLoading(true);
        const result = await GetMessages(room, token);
        if (result.success) {
            setGroup(result.data.group);
            setMessages(result.data.messages);
        }
        setLoading(false);
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            {loading ? <Loading /> : messages.length === 0 ?
                <Empty /> : <Feeds room={room} group={group} messages={messages} setMessages={setMessages} callback={callback}/>}
        </div>
    )
}

const Feeds = ({ room, group, messages, setMessages, callback }: { room: string, group: string, messages: any, setMessages: Function, callback: Function }) => {
    const { session } = useContext(AuthContext);
    const { socket } = useContext(SocketContext);
    const [text, setText] = useState("");
    const [ping] = useState(new Audio(process.env.REACT_APP_API_URL + '/audio/notif'));

    const timezone = session.global?.timezone as string;
    const me = session.me;
    const bodyRef: any = useRef(null);

    useEffect(() => {
        socket.on("receive-message", async (message: any) => {
            setMessages((curr:any) => [...curr, message]);
            scrollToBottom();

            if(message.createdBy.id !== me.id) {
                ping.muted = false;
                ping.play();
            }
        });

        scrollToBottom();
        return () => { socket.off("receive-message") }
    }, [socket]);

    const handleTextChange = (el: any) => {
        setText(el.target.value);
    }

    const handleSendClick = () => {
        socket?.emit("send-message", text, room);
        setText("");
    }

    const handleEnterKey = (event: any) => {
        event.preventDefault();
        if (event.key === "Enter") {
            handleSendClick();
        }
    }

    const handleCallback = (event: string) => {
        callback(event);
    }

    const scrollToBottom = () => {
        if(bodyRef.current !== null) {
            bodyRef.current.scrollIntoView({behavior: "smooth"});
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.feed}>
            <div className="header">
                <IconButton onClick={() => {handleCallback("close")}}>
                    <Icon>arrow_back</Icon>
                </IconButton>

                <Typography variant="subtitle2" color="primary">
                    {group}
                </Typography>
            </div>

            <div className="messages">
                {messages.map((m: any) => {
                    return (
                        <div key={m.id} className={`message ${m.createdBy.id === me.id ? "bubble-right" : ""}`}>
                            {m.isSystem ? (
                                <div className="system">
                                    {m.message === "Group created" ? (
                                        m.createdBy.id === me.id ? "You created this group" : `${m.createdBy.firstname} created this group`
                                    ) : m.message} {toHumanDate(m.createdAt, timezone)}
                                </div>
                            ) : (<div className="text">
                                <Typography variant="caption">
                                    {m.createdBy.firstname} {toHumanDate(m.createdAt, timezone)}
                                </Typography>

                                <Typography variant="body2">
                                    {m.message}
                                </Typography>
                            </div>)}
                        </div>
                    )
                })}
                <div ref={bodyRef}></div>
            </div>

            <div className="textarea">
                {/* <IconButton color="secondary">
                    <Icon>attach_file</Icon>
                </IconButton> */}

                <FormGroup id="textarea" label="" required={false}>
                    <InputField
                        id="group"
                        value={text}
                        onChange={handleTextChange}
                        placeholder="Message"
                        onKeyUp={handleEnterKey}
                    />
                </FormGroup>

                <IconButton color="primary" onClick={handleSendClick}>
                    <Icon>send</Icon>
                </IconButton>
            </div>
        </div>
    )
}

const Empty = () => {
    const classes = useStyles();
    return (
        <div className={classes.empty}>
            <NoRecordFound
                caption="You don't have any messages"
            />
        </div>
    )
}

export default Messages
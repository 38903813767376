import FormBtn from 'components/common/form/FormBtn'
import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import HPaper from 'components/common/HPaper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        maxWidth: "500px"
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    btnWrapper: {
        marginTop: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",

        "& button": {
            maxWidth: "80px",
            marginLeft: theme.spacing(1)
        }
    }
}));

const IdlePrompt = ({handleClose, handleLogout}:{handleClose:Function, handleLogout: Function}) => {
    const [counter, setCounter] = useState(10);

    let timer:any = null;

    useEffect(() => {
        timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if(counter === 0) {
            handleLogout();
        }
        return () => clearInterval(timer);
    },[counter]);

    const handleStay = () => {
        clearInterval(timer);
        handleClose();
    }

    const classes = useStyles();
    return (
        <HPaper className={classes.root}>
            <Typography variant="subtitle1" className={classes.subtitle}>
                Session Timeout
            </Typography>

            <Typography variant="body1">
                Your session is about to expire in {counter} seconds due to inactivity. 
                You will be redirected to the login page.
            </Typography>

            <div className={classes.btnWrapper}>
                <FormBtn label="Logout" onClick={() => handleLogout()} color="primary" variant="outlined"/>
                <FormBtn label="Stay" onClick={handleStay} color="primary" />
            </div>
        </HPaper>
    )
}

export default IdlePrompt
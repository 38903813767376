import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import HeaderStyles from './HeaderStyles';

const MenuMobile = ({handleMobileMenuOpen}:{handleMobileMenuOpen:Function}) => {
    const classes = HeaderStyles();

    return (
        <div className={classes.sectionMobile}>
            <IconButton
                aria-label="show more"
                aria-controls="primary-search-account-menu-mobile"
                aria-haspopup="true"
                onClick={(e) => handleMobileMenuOpen(e)}
                color="inherit"
            >
                <MoreIcon />
            </IconButton>
        </div>
    );
}

export default MenuMobile;
import { Icon, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Guest from 'pages/layouts/Guest';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '30px',
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        "& h2": {
            fontWeight: 600,
            fontSize: "2rem",
            color: theme.palette.primary.main
        },

        "& h4": {
            fontSize: "1rem",
            color: theme.palette.secondary.main,
            fontWeight: 300,
            marginTop: theme.spacing(2)
        },

        "& .MuiIcon-root": {
            fontSize: "6rem",
            marginTop: theme.spacing(4),
            color: theme.palette.secondary.light
        }
    },
}));

const Offline = () => {
    const classes = useStyles();
    return (
        <Guest>
            <div className={classes.root}>
                <Icon>construction</Icon>
                <Typography variant="h2" align="center">
                    SYSTEM UPDATE
                </Typography>

                <Typography variant="h4" align="center">
                    Helix is currently down for maintenance.<br/>
                    Please check again later. Thank you.
                </Typography>                
            </div>
        </Guest>
    )
};

export default Offline;
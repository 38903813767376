import { Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { MouseEventHandler } from 'react';

interface IFormBtn {
	className?: string,
	label: string,
	type?: "button"|"submit",
	color: any,
	variant?: "outlined"|"contained"|"text",
	onClick?: MouseEventHandler,
	disabled?:boolean,
	id?:string,
}

const useStyles = makeStyles((theme:Theme) => ({
	root: {
        minWidth: theme.spacing(15),
        width: '100%',
        boxSizing: "border-box"
	}
}));

const FormBtn = ({label, type, className, color, variant="contained", onClick, disabled, id}:IFormBtn) => {
	const classes = useStyles();

	return (
		<Button 
			variant={variant} 
			color={color} 
			className={`${classes.root} ${className}`}
			type={type}
			onClick={onClick}
			disabled={disabled}
			id={id}
		>
			{label}
		</Button>
  	)
}

export default FormBtn
import { makeStyles, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ChangeEventHandler, FocusEventHandler } from 'react';

interface IInput {
	required?: boolean,
	id?: string,
	label?: string,
	type?: "string"|"number"|"date"|"email",
	className?: string,
	multiline?:boolean,
	minRows?:number,
	value?: any,
	defaultValue?:any,
	onChange?: ChangeEventHandler,
	onBlur?: any,
	onKeyUp?: any,
	error?: any,
	helperText?: any,
	disabled?:boolean,
	inputProps?:object,
	InputProps?:object,
	placeholder?: string,
	variant?: "filled" | "outlined" | "standard"
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		boxSizing: "border-box",
		fontSize: "1rem",

		"& .MuiOutlinedInput-inputAdornedStart":{
            paddingLeft: theme.spacing(1),
        }
	}
}));

const InputField = ({ required, id, label, type, className, multiline, minRows, value, defaultValue, onChange, onBlur, onKeyUp, error, helperText, disabled, inputProps, InputProps, placeholder, variant="outlined" }: IInput) => {
	const classes = useStyles();

	return (
		<TextField 
			required={required}
			id={id}
			label={label}
			type={type}
			variant={variant}
			size="small"
			multiline={multiline}
			rows={minRows}
			className={`${classes.root} ${className}`}
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			onBlur={onBlur}
			onKeyUp={onKeyUp}
			error={error}
			helperText={helperText}
			disabled={disabled}
			inputProps={inputProps}
			InputProps={InputProps}
			placeholder={placeholder}
		/>
  	)
}

export default InputField
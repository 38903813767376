import * as moment from 'moment-timezone';

export const DEFAULT_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm A';
const DEFAULT_TIMEZONE = 'UTC';

export const today = (format: string = DEFAULT_FORMAT) => {
    return moment.tz(DEFAULT_TIMEZONE).format(format);
}

export const format = (strDate: string, timezone:string, format:string = DEFAULT_DATETIME_FORMAT) => {
    return moment.tz(strDate, timezone).format(format);
}

export const toHumanDate = (strDate: string, timezone:string, format:string = DEFAULT_DATETIME_FORMAT) => {
    const isValid = moment.utc(strDate);
    if(!isValid.isValid()) {
        return <span>---</span>
    }

    return <span>{moment.utc(strDate).tz(timezone).format(format)}</span>;
}

export const diffInDays = (startDate:string, endDate:string) => {
    const start = moment.tz(startDate, DEFAULT_TIMEZONE);
    const end = moment.tz(endDate, DEFAULT_TIMEZONE);
    return start.diff(end, "days");
}
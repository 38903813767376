import { Typography } from '@material-ui/core'
import HPaper from 'components/common/HPaper'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from 'formik';
import FormGroup from 'components/common/form/FormGroup';
import InputField from 'components/common/form/InputField';
import { CreateRoom, GetUsers } from 'actions/chat/Actions';
import AuthContext from 'context/AuthContext';
import NoRecordFound from 'components/common/NoRecordFound';
import Loading from 'components/common/Loading';
import FormBtn from 'components/common/form/FormBtn';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    filter: {
        margin: theme.spacing(1.5, 0, 1)
    },
    users: {
        background: theme.palette.secondary.light,
        padding: theme.spacing(1),

        "& .list": {
            marginTop: theme.spacing(1),
            height: "300px",
            overflowY: "auto",
        }
    },
    search: {
        "& .MuiOutlinedInput-root": {
            background: theme.palette.background.paper
        }
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    empty: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        border: `1px solid ${theme.palette.secondary.light}`,

        "& .MuiSvgIcon-root": {
            color: theme.palette.grey[300],
            width: "4rem",
            height: "4rem"
        },

        "& .MuiTypography-body2": {
            color: theme.palette.secondary.main,
        }
    },
    contact: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        background: theme.palette.background.paper,
        padding: "12px",
        marginBottom: "8px",
        position: "relative",
        cursor: "pointer",

        "& .name": {
            marginLeft: "35px"
        },

        "& input": {
            display: "none"
        },

        "& input:checked ~ .checkmark": {
            backgroundColor: theme.palette.primary.main,

            "&::after": {
                display: "block"
            }
        },

        "& .checkmark": {
            position: "absolute",
            top: "26px",
            left: "10px",
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            backgroundColor: theme.palette.secondary.light,
        },

        "& .checkmark::after": {
            content: '""',
            display: "none",
            position: "absolute",
            left: "8px",
            top: "4px",
            width: "5px",
            height: "10px",
            border: `solid ${theme.palette.secondary.contrastText}`,
            borderWidth: "0 2px 2px 0",
            transform: "rotate(45deg)"
        },
    },
    btnWrapper: {
        marginTop: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",

        "& button": {
            maxWidth: "80px",
            marginLeft: theme.spacing(1)
        }
    },
}));

const Contacts = ({ handleCallback }: { handleCallback: Function }) => {
    const { session } = useContext(AuthContext);

    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState([]);
    const [term, setTerm] = useState("");
    const [loading, setLoading] = useState(false);

    const token = session.token as string;
    const { enqueueSnackbar } = useSnackbar();

    const fetchUsers = async () => {
        const query = `?term=${term}`;
        const result = await GetUsers(query, token);
        if (result.success) {
            setUsers(result.data);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [term])

    const formik = useFormik({
        initialValues: {
            group: "",
            users: []
        },
        onSubmit: async (values: any) => {
            if(selected.length === 0) {
                enqueueSnackbar("Please select at least one recipient", {variant: "error"});
                return false;
            }

            setLoading(true);

            values.users = selected;
            const result = await CreateRoom(values, token);
            if(result.success) {
                handleCallback(result.data.id);
            }else {
                enqueueSnackbar(result.message, {variant: "error"});
            }

            setLoading(false);
        }
    });

    const handleSearchUser = (el: any) => {
        setTerm(el.target.value);
    }

    const handleSubmitClick = () => {
        formik.submitForm();
    }

    const classes = useStyles();
    return (
        <HPaper className={classes.root}>
            <div className="header">
                <Typography variant="subtitle1" className={classes.subtitle}>
                    New Message/Group
                </Typography>
            </div>

            <form autoComplete="false" noValidate onSubmit={formik.handleSubmit}>
                <FormGroup id="group" label="Group/Room" required={false}>
                    <InputField
                        id="group"
                        value={formik.values.group}
                        onChange={formik.handleChange}
                        error={formik.touched.group && Boolean(formik.errors.group)}
                        helperText={formik.touched.group && formik.errors.group}
                        placeholder="Optional"
                    />
                </FormGroup>

                <FormGroup id="filter" label="Recipients" required={true} className={classes.filter}>
                </FormGroup>

                <div className={classes.users}>
                    <InputField
                        id="user-filter"
                        placeholder="Search user"
                        value={term}
                        onChange={handleSearchUser}
                        className={classes.search}
                    />

                    <div className="list">
                        {loading ? <Loading /> : (users.length > 0 && users ?
                            users.map((u: any) => {
                                return <Contact user={u} selected={selected} setSelected={setSelected} key={u.id} />
                            }) : <Empty />)}
                    </div>
                </div>
            </form>

            <div className={classes.btnWrapper}>
                <FormBtn label="Close" variant="outlined" color="primary" onClick={() => handleCallback("close")} />
                <FormBtn label="Create" variant="contained" color="primary" onClick={handleSubmitClick} disabled={loading} />
            </div>
        </HPaper>
    )
}

const Contact = ({ user, selected, setSelected }: { user: any, selected: any, setSelected: Function }) => {
    const classes = useStyles();
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const uid = selected.find((x: string) => x === user.id);
        if (typeof (uid) !== "undefined") {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [selected]);

    const handleSelectClick = (el: any, user: any) => {
        const checked = el.target.checked;

        if (checked) {
            setSelected([...selected, user.id]);
        } else {
            setSelected(selected.filter((x: any) => x !== user.id));
        }
    }

    return (
        <label htmlFor={`check-${user.id}`} className={`${classes.contact} ${isChecked ? "selected" : ""}`} key={user.id}>
            <div className="check">
                <input
                    type="checkbox"
                    id={`check-${user.id}`}
                    value={JSON.stringify(user)}
                    onChange={(e) => handleSelectClick(e, user)}
                    checked={isChecked}
                />
                <span className="checkmark"></span>
            </div>

            <div className="name">
                <Typography variant="subtitle2">
                    {user.name}
                </Typography>
                <Typography variant="caption">
                    {user.emailAddress}
                </Typography>
            </div>
        </label>
    )
}

const Empty = () => {
    const classes = useStyles();
    return (
        <div className={classes.empty}>
            <NoRecordFound
                caption="No contacts were found matching your selection"
            />
        </div>
    )
}

export default Contacts
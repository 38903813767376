import { createContext } from "react"; 
import { io, Socket } from "socket.io-client";
import { ServerToClientEvents, ClientToServerEvents } from "types/socket";

export type ISocket = Socket<ServerToClientEvents, ClientToServerEvents> | null;

export type SocketState = {
    socket: ISocket
}

const SocketContext = createContext<any>({socket: null});
export default SocketContext;
import React from 'react';
import Wrapper from './Wrapper';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from 'themes/default';

function App() {
	return (
		<Router basename={process.env.REACT_APP_PUBLIC_URL}>
			<ThemeProvider theme={theme}>
				<React.Fragment>
					<CssBaseline />
					<Wrapper />
				</React.Fragment>
			</ThemeProvider>
		</Router>
	);
}

export default App;

import sha256 from "crypto-js/sha256";
import { today } from "components/helpers/DateHelper";

export const generateSign = async (appKey:string, data:object) => {
    const concat = `${JSON.stringify(data)}|${today()}|${appKey}`;
    return sha256(concat).toString();
}

export const moneyFormat = (currency:string, amount:string|number) => {
    return currency + Number(amount).toLocaleString("en-US", {maximumFractionDigits: 2});
}

export const isNullOrEmpty = (text:string|null) => {
    if(text === null) return true;

    let str = text.trim();
    return str.length === 0;
}

export const isUndefined = (text:any) => {
    return typeof(text) === "undefined";
}

export const strClean = (text:string|null) => {
    if(text === null) return text;
    return text.trim();
}

export const capsFirst = (text: string) => {
    if(isNullOrEmpty(text)) return false;
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const ellipsify = (text: string, max: number) => {
    return text.substring(0, max-1) + (text.length > max ? '...' : '');
}
import { createContext } from "react";

interface IGlobal {
    countryIsoCode: string | null,
    currency: string|null,
    currencyIsoCode: string|null,
    timezone: string|null,
    landlineMask: string|null,
    mobileMask: string|null,
    buildVersion: string|null
}

export type AuthSession = {
    isAuthenticated: boolean | null,
    role?: string | null,
    token?: string,
    csrf?: string,
    permissions: string[],
    me?: any
    global?: IGlobal
}

export type AuthState = {
    session: AuthSession,
    setSession: Function
}

export const authDefault: AuthState = {
    session: {
        isAuthenticated: null,
        permissions: [],
        global: {
            countryIsoCode: null,
            currency: null,
            currencyIsoCode: null,
            timezone: null,
            landlineMask: null,
            mobileMask: null,
            buildVersion: null
        }
    },
    setSession: () => {}
}

const AuthContext = createContext<AuthState>(authDefault);
export default AuthContext;
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},

}));

const HPaper = ({className, children }: { className?:string, children: any }) => {
	const classes = useStyles();

	return (
		<Paper className={`${classes.root} ${className}`}>
			{children}
		</Paper>
	)
}

export default HPaper
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

export const RenderDesktop = ({anchorEl, handleMenuClose}: {anchorEl:null | HTMLElement, handleMenuClose: Function}) => {
    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(anchorEl);
    return (
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={() => handleMenuClose()}
      >
        <MenuItem onClick={() => handleMenuClose()}>Profile</MenuItem>
        <MenuItem onClick={() => handleMenuClose()}>My account</MenuItem>
      </Menu>
    );
}
import { useContext, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from './MenuItem';
import { ListItem, makeStyles } from '@material-ui/core';
import { IMenuData } from './MenuData';

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`
    },
    menuIcon: {
        fontSize: '1.3rem'
    },
    activeIcon: {
        color: `${theme.palette.primary.contrastText} !important`,
    },
    defaultIcon: {
        color: `${theme.palette.grey.A200}`
    },
    itemText: {
        '& .MuiTypography-body1': {
            fontSize: '0.90rem',
            fontWeight: 600
        }
    },
    submenu: {
        background: theme.palette.grey.A700
    },
}));

const SubMenu = ({ item, selected, selectMenu }: { item: IMenuData, selected: string, selectMenu: Function }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick} key={item.name}>
                <ListItemIcon className={selected === item.id ? classes.activeIcon : classes.defaultIcon}>
                    <Icon className={classes.menuIcon}>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={item.name} className={classes.itemText} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" className={classes.submenu}>
                    {item.children.map((subItem, index) => (
                        <MenuItem item={subItem} selected={selected} selectMenu={selectMenu} key={subItem.id} />
                    ))}
                </List>
            </Collapse>
        </>
    );
}

export default SubMenu;
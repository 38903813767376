import { Badge, Hidden, IconButton } from '@material-ui/core';
import SocketContext from 'context/SocketContext';
import React, { useContext, useEffect, useState } from 'react'
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import Overlay from 'components/common/Overlay';
import Messenger from './overlays/Messenger';
import Messages from './overlays/Messages';

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    customBadge: {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.light
    },
    drawer: {
        "& .MuiDrawer-paperAnchorRight": {
            background: theme.palette.background.paper,
            width: "100%",
            maxWidth: "500px",
            padding: theme.spacing(2),
            overflow: "hidden",
        },

        "& .close": {
            display: "none"
        }
    },
}));

const ChatNotification = () => {
    const { socket } = useContext(SocketContext);
    const [ping] = useState(new Audio(process.env.REACT_APP_API_URL + '/audio/notif'));
    const [open, setOpen] = useState(false);
    const [openMessages, setOpenMessages] = useState(false);
    const [room, setRoom] = useState<string|null>(null);

    useEffect(() => {
        if (socket !== null) {
            socket.on("notify-user", async (room: string) => {
                console.log(room);

                ping.muted = false;
                ping.play();
            });
            return () => { socket.off("notify_user") }
        }
    }, [socket]);

    const handleIconClick = () => {
        setOpen(true);
    }

    const handleCallback = (event: string) => {
        if(event !== "close") {
            setRoom(event);
            setOpenMessages(true)
        }else {
            setRoom(null);
            setOpenMessages(false);
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <IconButton color="inherit" onClick={handleIconClick}>
                <Badge badgeContent={0} classes={{ badge: classes.customBadge }}>
                    <MailIcon />
                </Badge>
            </IconButton>
            
            <Overlay open={open} setOpen={setOpen} className={classes.drawer}>
                <Messenger callback={handleCallback} />
            </Overlay>

            <Overlay open={openMessages} setOpen={setOpenMessages} className={classes.drawer}>
                <Messages callback={handleCallback} room={room as string} />
            </Overlay>
        </div>
    )
}

export default ChatNotification;

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import SidebarStyles from './SidebarStyles';
import Brand from './Brand';
import Menu from './menu/Menu';

const Sidebar = ({open, handleDrawerClose}: {open: boolean, handleDrawerClose: Function}) => {
  const classes = SidebarStyles();

  return (
    <Drawer variant="permanent" className={clsx(classes.drawer, {
      [classes.drawerOpen]: open,
      [classes.drawerClose]: !open,
    })}
    classes={{
      paper: clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })
    }}
    >
      <Brand handleDrawerClose={handleDrawerClose} />
      <Menu />
    </Drawer>      
  );
}

export default Sidebar;
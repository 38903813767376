import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        boxSizing: "border-box",
        width: "100%",
        height: "100%",        
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",

        "& .MuiPaper-elevation1": {
            maxHeight: "800px",
            overflowY: "auto",
        }
    },
    child: {
        position: "relative",
    },
    close: {
        position: "absolute",
        top: "14px",
        right: "4px"
    }
}));

interface IModal {
    open: boolean,
    handleClose: Function,
    children: any,
    className?:string
}

const SimpleModal = ({ open, handleClose, children, className }: IModal) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={() => handleClose()} onEscapeKeyDown={() => handleClose()}>
            <div className={`${classes.root} ${className}`}>
                <div className={`${classes.child} child`}>
                    <Button size="small" className={classes.close} onClick={() => handleClose()}>
                        <CloseIcon />
                    </Button>

                    {children}
                </div>
            </div>
        </Modal>
    )
}

export default SimpleModal
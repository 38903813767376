import { Redirect, Route, RouteProps } from "react-router";
import { useContext } from "react";
import AuthContext from "context/AuthContext";

interface ProtectedRouteProps extends RouteProps {
    component: any;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { session } = useContext(AuthContext);
    const {component: Component, ...rest} = props;

    return (
        <Route
            {...rest}
            render={props => {
                if(session.isAuthenticated) {
                    return <Component {...props} />
                }else {
                    return <Redirect to = {
                        {
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }
            }}
        />
    );
}

export default ProtectedRoute;
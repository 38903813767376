import {useState} from 'react'
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const Navigators = () => {
    const [open, setOpen] = useState(true);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
        <>
          <Header open={open} handleDrawerOpen={handleDrawerOpen} />
          <Sidebar open={open} handleDrawerClose={handleDrawerClose}/>
        </>
    );
}

export default Navigators;
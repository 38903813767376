import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    drawer : {
        position: "relative",

        "& .close": {
            position: "absolute",
            right: "14px",
            top: "11px"
        },

        "& .MuiDrawer-paperAnchorRight": {
            background: theme.palette.background.paper,
            width: "45%",
            padding: theme.spacing(2),
        }
    }
}));

const Overlay = ({children, open, setOpen, className}:{children:any, open:boolean, setOpen:Function, className?:string}) => {
    const classes = useStyles();

    return (
        <div 
            className={classes.root}
        >
             <SwipeableDrawer
                disableDiscovery={true}
                disableBackdropClick={true}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(false)}
                className={`${classes.drawer} ${className}`}
            >
                <Button className="close" onClick={() => setOpen(false)}>
                    <CloseIcon />
                </Button>
                {children}
            </SwipeableDrawer>
        </div>
    );
}

export default Overlay;
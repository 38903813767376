import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import SidebarStyles from './SidebarStyles';
import logo from 'assets/images/logo.png'

const Brand = ({handleDrawerClose}:{handleDrawerClose: Function}) => {
    const classes = SidebarStyles();
    return (
        <React.Fragment>
            <div className={classes.toolbar}>
                <div className={classes.logoWrap}>
                    <img src={logo} alt="company_logo" className={classes.logo}/>
                </div>

                <IconButton onClick={() => handleDrawerClose()} color="secondary">
                    <MenuIcon />
                </IconButton>
            </div>
        </React.Fragment>
    );
}

export default Brand;
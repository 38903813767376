import HeaderStyles from './HeaderStyles';
import { useContext, useState } from 'react';
import { IPop } from 'interfaces/Popover';
import { Popover } from '@material-ui/core';
import { Logout } from 'actions/security/Actions';
import AuthContext from 'context/AuthContext';
import BadgeAvatar from 'components/common/BadgeAvatar';
import ChatNotification from './ChatNotification';
import SystemNotification from './SystemNotification';

const MenuDesktop = () => {
    const classes = HeaderStyles();
    const { session, setSession } = useContext(AuthContext);
    
    const csrf = session.csrf as string;
    const permissions = session.permissions;

    const [popover, setPopover] = useState<IPop>({
        openedPopoverId: null,
        anchorEl: null
    });

    const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>, popoverId: string) => {
        event.preventDefault();

        setPopover({
            openedPopoverId: popoverId,
            anchorEl: event.target
        });
    }

    const handleActionClose = () => {
        setPopover({
            openedPopoverId: null,
            anchorEl: null
        });
    }

    const handleLogout = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        await Logout(csrf, session.token);
        setSession({ ...session, isAuthenticated: false });
    }

    return (
        <div className={classes.sectionDesktop}>
            {permissions.includes(btoa("_internal_chat")) ? <ChatNotification /> : null}

            {/* <SystemNotification/> */}

            <BadgeAvatar user={session.me} onClick={handleActionClick}/>
            <Popover
                open={popover.openedPopoverId === "profile"}
                anchorEl={popover.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleActionClose}
                classes={{
                    paper: classes.pop
                }}
            >
                <div className="arrow"></div>
                <div className="links">
                    <a href="#" onClick={handleLogout}>Logout</a>
                </div>
            </Popover>
        </div>
    );
}

export default MenuDesktop;
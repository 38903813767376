import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;

const SidebarStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		borderRight: 0
	},
	drawerOpen: {
		width: drawerWidth,
		background: theme.palette.grey.A400,
		color: theme.palette.grey.A200,
		borderRight: '0px solid red !important',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflow: 'none'
	},
	drawerClose: {
		background: theme.palette.grey.A400,
		color: theme.palette.grey.A200,
		borderRight: '0px solid red !important',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(0, 0),
		background: theme.palette.background.paper,
		...theme.mixins.toolbar,
		boxShadow: '0 4px 4px -3px rgb(0 0 0 / 20%)'
	},
	brand: {
		marginLeft: "1rem !important",
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	divider: {
		background: "#595959"
	},
	logoWrap: {
		textAlign: 'center'
	},
	logo: {
		width: '70%'
	}
}));

export default SidebarStyles;
import { IParams, tryFetch } from "components/helpers/FetchApi"

export const GetRooms = async (query:string, token:string) => {
    let params:IParams = {
        url: `/chat/rooms${query}`,
        method: "GET",
        payload: {},
        token
    }
    return await tryFetch(params);
}

export const GetMessages = async (id:string, token:string) => {
    let params:IParams = {
        url: `/chat/${id}/messages`,
        method: "GET",
        payload: {},
        token
    }
    return await tryFetch(params);
}

export const GetUsers = async (query:string, token:string) => {
    let params:IParams = {
        url: `/chat/users${query}`,
        method: "GET",
        payload: {},
        token
    }
    return await tryFetch(params);
}

export const CreateRoom = async (payload: object, token:string) => {
    let params:IParams = {
        url: `/chat/rooms`,
        method: "POST",
        payload: payload,
        token
    }
    return await tryFetch(params);
}

export const ReadMessages = async (payload: object, token:string) => {
    let params:IParams = {
        url: `/chat/read-messages`,
        method: "POST",
        payload: payload,
        token
    }
    return await tryFetch(params);
}
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import background from 'assets/images/fade.png'
import logo from 'assets/images/logo.png';
import { today } from 'components/helpers/DateHelper';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'end',
		alignItems: 'center',
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${background})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',

		'&::before': {
			content: '""',
			width: '100%',
			height: '100%',
			display: 'flex',
			backgroundColor: 'rgba(168,197,241,0.15)',
			position: 'absolute',
			zIndex: 1,
			top: '0px',
			left: '0px',
			right: 'inherit'
		}
	},
	wrapper: {
		width: '100%',
		maxWidth: '500px',
		height: '100%',
		overflowY: 'hidden',
		zIndex: 10,
		position: 'relative'
	},
	content: {
		minHeight: '100%',
		background: theme.palette.background.paper,
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'start',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2)
		},
	},
	logo: {
		width: '100%',
		maxWidth: '250px',
		padding: '20px 0px',
	},
	footer: {
		marginTop: 'auto',
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",

		'& span': {
			display: 'block',
			fontSize: '0.80rem',
			fontWeight: 300,
			textAlign: 'center',

			'& a': {
				fontWeight: 400
			}
		}
	},
	vaslogic: {
		width: "100%",
		maxWidth: "100px",
		marginBottom: theme.spacing(1)
	}
}));

const Guest = ({ children }: { children: any }) => {
	const { session } = useContext(AuthContext);
	const [version, setVersion] = useState("");

	useEffect(() => {
		if(session !== null) {
			const v = session.global?.buildVersion as string;
			setVersion(v);
		}
	},[session]);

	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<img src={logo} alt="logo" className={classes.logo} />
					{children}
					<div className={classes.footer}>
						<Typography variant="caption">
							&copy; {today('YYYY')} | Version {version}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Guest
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        background: 'rgba( 205, 205, 205, 0.5 )',

        "& .MuiCircularProgress-root": {
            marginBottom: theme.spacing(1.5),
            width: "30px !important",
            height: "30px !important"
        }
    }
}));

const PageLoaderBackDrop = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress/>
            <Typography variant="body2">
                Please wait...
            </Typography>
        </div>
    )
}

export default PageLoaderBackDrop;
import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Slide } from '@material-ui/core';
import PrivateRoute from 'components/security/PrivateRoute';
import PublicRoute from 'components/security/PublicRoute';
import MenuContext from 'context/MenuContext';
import AuthContext, { authDefault, AuthSession } from 'context/AuthContext';
import PageLoader from './security/PageLoader';
import { KeepAlive, GetCSRF, InitializeSettings } from 'actions/security/KeepAlive';
import { SnackbarProvider } from 'notistack';
import { GetUser, GetUserPermission } from 'actions/security/Actions';
import Offline from 'pages/security/Offline';

const Wrapper = () => {
	const [session, setSession] = useState<AuthSession>(authDefault.session);
	const [isLoading, setIsLoading] = useState(true);
	const [isOffline, setIsOffline] = useState(false);

	useEffect(() => {
		getGlobalSettings();
	}, []);

	const getGlobalSettings = async () => {
		try {
			setIsLoading(true);

			const response: any = await InitializeSettings();
			
			if (!response || response.status === 500) {
				setIsOffline(true);
			}
			
			if (response.status === 200) {
				const data = await response.json();
				await checkIfAuthenticated(data);
			}
		}catch(err) {
			setIsOffline(true);
		}finally{
			setIsLoading(false);
		}
	}

	const checkIfAuthenticated = async (data: any) => {
		const newSession = {
			...session,
			global: {
				countryIsoCode: data.country,
				currencyIsoCode: data.currency_iso_code,
				currency: data.currency_symbol,
				landlineMask: data.landline_number_format,
				mobileMask: data.mobile_number_format,
				timezone: data.timezone,
				buildVersion: data.build_version
			}
		}

		const csrf = await GetCSRF();
		const response = await KeepAlive(session.token, csrf);
		if (response.success && response.code === "200.100.000") {
			const meResult = await GetUser(response.data.token);
			if(meResult.success) {
				const result = await GetUserPermission(csrf, response.data.token);
				if (result.success) {
					setSession({
						...newSession,
						isAuthenticated: true,
						token: response.data.token,
						csrf: csrf,
						permissions: typeof(result.data.permissions) !== "undefined" ? result.data.permissions.split(",") : [],
						role: result.data.role,
						me: meResult.data
					});
				}
			}
			return;
		}

		setSession({
			...newSession,
			isAuthenticated: false,
			permissions: [],
			role: null,
			csrf,
		});
	}

	return (
		<>
			{isLoading ? <PageLoader /> : (isOffline ? <Offline /> : (
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
				>
					<AuthContext.Provider value={{ session, setSession }}>
						{session.isAuthenticated === null ? <PageLoader /> : <PageContent />}
					</AuthContext.Provider>
				</SnackbarProvider>
			))}
		</>
	)
}

const PageContent = () => {
	const { session } = useContext(AuthContext);
	const [paths, setPaths] = useState<Array<string>>([]);

	return (
		<MenuContext.Provider value={{ paths, setPaths }}>
			<Box display="flex" style={{ height: "calc(100% - 64px)", boxSizing: "border-box" }}>
				{session.isAuthenticated ? <PrivateRoute /> : <PublicRoute isAuthenticated={session.isAuthenticated} />}
			</Box>
		</MenuContext.Provider>
	);
}

export default Wrapper;
import { createContext } from "react"; 

export type MenuState  = {
    paths: Array<string>,
    setPaths: Function
}

export const menuPathDefault: MenuState = {
    paths: [],
    setPaths: () => {}
}

const MenuContext = createContext<MenuState>(menuPathDefault);
export default MenuContext;
import { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Page from 'components/main/Components';
import PageContext from 'context/PageContext';
import PageLoaderBackDrop from './PageLoaderBackdrop';

const PublicRoute = ({isAuthenticated}:{isAuthenticated: boolean|null}) => {
	const [isPageLoading, setIsPageLoading] = useState(false);
	
	return (
		<PageContext.Provider value={{ isPageLoading, setIsPageLoading }}>
			{isPageLoading ? <PageLoaderBackDrop/> : null}
			<Switch>
				<Route path="/login" exact>
					{isAuthenticated ? <Redirect to="/" /> : <Page.Login />}
				</Route>

				<Route path="/verify/:token" exact>
					<Page.Verify/>
				</Route>

				<Route path="/password-reset" exact>
					<Page.PasswordReset/>
				</Route>

				<Route exact >
					<Redirect to="/login" />
				</Route>
			</Switch>
		</PageContext.Provider>
	)
}

export default PublicRoute;
import { today } from './DateHelper';
import { generateSign } from './StringHelper';

export interface IParams {
    url: string,
    method?: 'POST' | 'GET',
    data?: string,
    token?: string,
    payload: object | Array<object>,
    formData?: FormData,
    csrf?: string
}

export interface IResponse {
    code: number | string,
    message: string,
    success: boolean,
    data?: any
}

export const fetchLong = async (params: IParams): Promise<IResponse> => {
    const LONG_TIMEOUT = 300000;
    const appKey = process.env.REACT_APP_APP_KEY as string;

    const headers: any = {
        'Content-Type': 'application/json',
        'X-AppKey': appKey,
        'X-Signature': await generateSign(appKey, params.payload),
    }

    if (typeof (params.token) !== 'undefined') {
        headers['Authorization'] = `Bearer ${params.token}`
    }

    if(typeof(params.csrf) !== "undefined") {
        headers["CSRF-Token"] = params.csrf;
    }

    const method = typeof (params.method) !== 'undefined' ? params.method : 'POST';
    const url = process.env.REACT_APP_NODE_ENV === 'development' ?
        params.url : process.env.REACT_APP_API_URL + params.url;

    const response = await fetch(url, {
        method: method,
        headers: headers,
        body: method === 'POST' ? JSON.stringify(params.payload) : undefined
    });

    const json = await response.json();
    return json;
}

export const tryFetch = async (params: IParams): Promise<IResponse> => {
    const appKey = process.env.REACT_APP_APP_KEY as string;

    const headers: any = {
        'Content-Type': 'application/json',
        'X-AppKey': appKey,
        'X-Signature': await generateSign(appKey, params.payload),
    }

    if (typeof (params.token) !== 'undefined') {
        headers['Authorization'] = `Bearer ${params.token}`
    }

    if(typeof(params.csrf) !== "undefined") {
        headers["CSRF-Token"] = params.csrf;
    }
    
    const method = typeof (params.method) !== 'undefined' ? params.method : 'POST';
    const url = process.env.REACT_APP_NODE_ENV === 'development' ?
        params.url : process.env.REACT_APP_API_URL + params.url;

    const response = await fetch(url, {
        method: method,
        headers: headers,
        credentials: "include",
        mode: "cors",
        body: method === 'POST' ? JSON.stringify(params.payload) : undefined
    });

    const json = await response.json();
    return json;
}

export const stringFetch = async (params: IParams): Promise<string> => {
    const appKey = process.env.REACT_APP_APP_KEY as string;

    const headers: any = {
        'Content-Type': 'application/json',
        'X-AppKey': appKey,
        'X-Signature': await generateSign(appKey, params.payload),
    }

    if (typeof (params.token) !== 'undefined') {
        headers['Authorization'] = `Bearer ${params.token}`
    }

    const method = typeof (params.method) !== 'undefined' ? params.method : 'POST';
    const url = process.env.REACT_APP_NODE_ENV === 'development' ?
        params.url : process.env.REACT_APP_API_URL + params.url;

    const response = await fetch(url, {
        method: method,
        headers: headers,
        credentials: "include",
        mode: "cors",
        body: method === 'POST' ? JSON.stringify(params.payload) : undefined
    });

    return await response.text();
}

export const rawFetch = async (params: IParams) => {
    try {
        const appKey = process.env.REACT_APP_APP_KEY as string;

        const headers: any = {
            'Content-Type': 'application/json',
            'X-AppKey': appKey,
            'X-Signature': await generateSign(appKey, params.payload),
        }
    
        if (typeof (params.token) !== 'undefined') {
            headers['Authorization'] = `Bearer ${params.token}`
        }
    
        const method = typeof (params.method) !== 'undefined' ? params.method : 'POST';
        const url = process.env.REACT_APP_NODE_ENV === 'development' ?
            params.url : process.env.REACT_APP_API_URL + params.url;
    
        const response = await fetch(url, {
            method: method,
            headers: headers,
            credentials: "include",
            mode: "cors",
            body: method === 'POST' ? JSON.stringify(params.payload) : undefined
        });
    
        return response;
    }catch(err) {
        return false;
    }
}

export const tryUpload = async (params: IParams): Promise<IResponse> => {
    const appKey = process.env.REACT_APP_APP_KEY as string;

    let payload: any = {}
    const formData = params.formData as any;
    for(const pair of formData.entries()) {
        if(pair[0] === "file") continue;
        payload[pair[0]] = pair[1];
    }

    const headers: any = {
        'Authorization': `Bearer ${params.token}`,
        'X-AppKey': appKey,
        'X-Signature': await generateSign(appKey, payload),
        'CSRF-Token': params.csrf
    }

    const url = process.env.REACT_APP_NODE_ENV === 'development' ?
        params.url : process.env.REACT_APP_API_URL + params.url;

    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
        credentials: "include",
        mode: "cors",
    });

    const json = await response.json();
    return json;
}
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const HeaderStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	appBar: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.secondary.main,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	customBadge: {
		background: theme.palette.primary.main,
		color: theme.palette.secondary.light
	},
	mobileMenu: {
		background: theme.palette.secondary.dark,
		color: theme.palette.secondary.main
	},
	profileLink: {
		color: theme.palette.primary.main,

		"& .MuiSvgIcon-root": {
			width: theme.spacing(4),
			height: theme.spacing(4)
		}
	},
	pop: {
        width: "10vw",
        background: "transparent",
        boxShadow: "0 15px 10px rgba(0, 0, 0, 0.2)",

        "& .links": {
            background: theme.palette.background.paper,
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: "3px",

            "& a": {
                display: "block",
                padding: "8px 10px",
                width: "100%",
            },
        },

        "& .arrow": {
            position: "relative",
            marginTop: "10px",

            "&::before": {
                backgroundColor: theme.palette.secondary.light,
                content: '""',
                display: "block",
                position: "absolute",
                width: 12,
                height: 12,
                top: -6,
                transform: "rotate(45deg)",
                right: "15px"
            },

            "&::after": {
                backgroundColor: theme.palette.background.paper,
                content: '""',
                display: "block",
                position: "absolute",
                width: 12,
                height: 12,
                top: -4,
                transform: "rotate(45deg)",
                right: "15px"
            }
        }
    },
}));

export default HeaderStyles;
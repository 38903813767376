import { IParams, rawFetch, stringFetch, tryFetch } from "components/helpers/FetchApi";

export const GetCSRF = async () => {
    let params: IParams = {
        url: "/csrf",
        method: "GET",
        payload: {}
    }
    return await stringFetch(params);
}

export const KeepAlive = async (token:string|undefined, csrf:string) => {
    let params: IParams = {
        url: '/keep-alive',
        method: 'POST',
        token: token,
        payload: {},
        csrf
    }
    return await tryFetch(params);
}

export const InitializeSettings = async () => {
    let params: IParams = {
        url: "/init",
        method: "GET",
        payload: {}
    }
    return await rawFetch(params);
}
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { Shadows } from "@material-ui/core/styles/shadows"

const theme = createMuiTheme({
    typography: {
        fontSize: 15
    },
    shadows: Array(25).fill("none") as Shadows,
    palette: {
        background: {
            default: "#f3f3f4"
        },
        text: {
            primary: "#2e323a"
        },
        primary: {
            main: "#009BF9",
            contrastText: "#ffffff"
        },
        secondary: {
            dark: "#587d9f",
            main: "#999c9e",
            light: "#f3f3f4",
            contrastText: "#ffffff"
        },
        error: {
            main: "#ed5565",
            contrastText: "#ffffff",
        },
        warning: {
            main: "#f8ac59",
            contrastText: "#ffffff"
        },
        info: {
            main: "#23c6c8",
            contrastText: "#ffffff"
        },
        success: {
            main: "#1c84c6",
            contrastText: "#ffffff",
            dark: "#44b700",
            light: "#0fff66"
        },
        grey: {
            100: "#e7eaec",
            200: "#eef0f1",
            300: "#dee2e6",
            400: "#40697d",
            500: "#a3a4a6",
            A200: "#a7b1c2",
            A100: "#c4c4c4",
            A400: "#2F4050",
            A700: "#293846"
        }
    },
    shape: {
        borderRadius: 3
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
                "&:hover": {
                    backgroundColor: "none"
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: "none",
            },
            wrapper: {
                alignItems: "flex-start"
            }
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    border: "1px solid #c4c4c4",
                },
                "&.Mui-focused": {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid #009BF9`,
                    }
                }
            },
            input: {
                padding: "12px 15px"
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 14px) scale(1)"
            }
        },
        MuiPaper: {
            root: {
                outline: "none"
            }
        }
    },
    props: {
        MuiButton: {
            disableRipple: true
        },
        MuiButtonGroup: {
            disableRipple: true
        },
        MuiButtonBase:{
            disableRipple: true,
        },
        MuiCheckbox: {
            disableRipple: true
        }
    }
});

export default theme;
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { IMenuData } from './MenuData';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`
    },
    menuIcon: {
        fontSize: '1.3rem'
    },
    activeIcon: {
        color: `${theme.palette.primary.contrastText} !important`,
    },
    defaultIcon: {
        color: `${theme.palette.grey.A200}`
    },
    itemText: {
        '& .MuiTypography-body1': {
            fontSize: '0.90rem',
            fontWeight: 500,
        }
    }
}));

const MenuItem = ({ item, selected, selectMenu }: { item: IMenuData, selected: string, selectMenu: Function }) => {
    const classes = useStyles();

    return (
        <ListItem button key={item.name}
            component={Link}
            to={item.path}
            selected={selected === item.id}
            onClick={() => selectMenu(item.id)}
            classes={{ selected: classes.active }}
        >
            <ListItemIcon className={`${selected === item.id ? classes.activeIcon : classes.defaultIcon}`}>
                <Icon className={classes.menuIcon}>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={item.name} className={classes.itemText} />
        </ListItem>
    );
}

export default MenuItem;